@import 'io-sanita-theme/theme/bootstrap-italia-base-config';

.iosanita-block-search.map {
  .search-top {
    h2.h5 {
      font-weight: $font-weight-semibold;
    }

    .subjects {
      display: flex;
      overflow: scroll;
      button {
        flex: 0 0 auto;
      }
      .chip.chip-primary.active {
        background-color: var(--bs-primary);
        color: var(--bs-primary-text);
        > .chip-label {
          color: var(--bs-primary-text);
        }
      }
    }
  }

  #geocoded-result {
    height: auto;
    width: 100%; //serve per farlo quadrato
    padding-bottom: 100%; //serve per farlo quadrato
    .leaflet-top {
      z-index: 400;
    }
  }
}
