@mixin rem-size($key, $value) {
  #{$key}: calc($value / 18) + rem;
}
@mixin rem-size-important($key, $value) {
  #{$key}: calc($value / 18) + rem !important;
}

@mixin margin-size($key, $value) {
  #{$key}: calc($value / 18) + em;
}

@mixin nearFullWidthBlocks($class) {
  .block.full-width[class*='bg-']:has(+ .block#{$class}),
  .block#{$class}:has(+ .block.full-width[class*='bg-']),
  .block#{$class}:has(+ .block.align.full),
  .block#{$class}:has(+ .block.iosanita-block-search),
  .block#{$class}:has(+ .block.image) {
    margin-bottom: 0 !important;
  }
}
