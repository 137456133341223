@import 'io-sanita-theme/theme/io-sanita/mixins';

.public-ui .quick-search-block {
  .form-group.search-bar-widget label.active {
    @include rem-size(font-size, 24);
  }

  // &:has(+ .block.image),
  // &:has(+ .block.listing.carouselTemplate) {
  //   margin-bottom: 0 !important;
  // }

  .quick-links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .btn-outline-primary {
      background-color: #fff;
    }
  }
}

@include nearFullWidthBlocks('.quick-search-block');
